<template>
	<div class="Downhole_settings" v-loading="loading">
		<div class="head">
			<h4>下井设置</h4>
		</div>
		<div class="body">
			<div class="form-wrap">
				<div class="fitem req">
					<label>时间设置</label>
					<div class="value">
						<el-input-number style="width: 300px" v-model="form.hourNum" controls-position="right"
							:min="1"></el-input-number>
						<p>超过*小时，记为下井</p>
					</div>
				</div>
				<div class="fitem req">
					<label>打卡点位</label>
					<div class="dianwei-wrap">
						<div class="dianwei_list">
							<div class="dwitem" v-for="(item, i) in cardRules" :key="i">
								<el-input v-model="item.position" placeholder="位置"></el-input>
								<el-input v-model="item.code" placeholder="编号"></el-input>
								<el-button v-if="i != 0" @click="delItem(i)" icon="el-icon-circle-close" type="text"></el-button>
							</div>
						</div>
						<div class="add-wrap">
							<el-button type="primary" @click="addItem()">新增</el-button>
						</div>
					</div>
				</div>
				<!-- <div class="bitem" v-for="(item, i) in arrMap[form.radio]" :key="i">
					<div class="title">第 {{ i + 1 }} 次上下班</div>
					<div class="inner">
						<div class="fitem req">
							<label>上班时间</label>
							<div class="value">
								<el-time-picker v-model="item.value1" :picker-options="{
										selectableRange: '18:30:00 - 20:30:00'
									}" placeholder="任意时间点"></el-time-picker>
							</div>
						</div>
						<div class="fitem req">
							<label>下班时间</label>
							<div class="value">
								<el-time-picker v-model="item.value1" :picker-options="{
										selectableRange: '18:30:00 - 20:30:00'
									}" placeholder="任意时间点"></el-time-picker>
							</div>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="head">
			<h4>下井带班设置</h4>
		</div>
		<div class="body">
			<div class="form-wrap">
				<div class="fitem req">
					<label>时间设置</label>
					<div class="value">
						<el-input style="width: 300px" v-model="form_daiban.hourNum" placeholder=""></el-input>
						<p>超过*小时，记为下井</p>
					</div>
				</div>
				<div class="fitem req">
					<label>选择下井带班部门</label>
					<div class="value">
						<bumen_cascader ref="bumen_cascader" @confirm="confirm_bumen_cascader_value" />
					</div>
				</div>
			</div>
			<div class="btns">
				<el-button type="primary" @click="do_save()">保存</el-button>
				<!-- <el-button >取消</el-button> -->
			</div>
		</div>
	</div>
</template>
<script>
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择
	export default {
		components: {
			bumen_cascader
		},
		data() {
			return {
				loading:false,
				id: 0,
				//下井
				form: {
					"id": 0,
					"title": "",
					"type": "2", //类型：1-考勤规则 2-下井设置 3-下井带班 4-免考勤
					"hourNum": "",
					"cardRules": "",
				},
				cardRules: [{
					"position": "",
					"code": ""
				}, ],

				//带班
				form_daiban: {
					"id": 0,
					"title": "",
					"type": "3", //类型：1-考勤规则 2-下井设置 3-下井带班 4-免考勤
					"hourNum": "",
					"departIds": "",
					"departItems": "",
				},

			};
		},
		computed: {},
		watch: {},
		created() {
			this.setView()
		},
		mounted() {},
		methods: {
			setView() {
				this.query_xiajin_detail()
				this.query_daiban_detail()
			},
			query_xiajin_detail() {
				this.loading=true
				this.$api("kaoqin/getRuleInfo", {
					// id: this.id
					type: 2
				}, "get").then((res) => {
					this.loading=false

					if (res.code == 200) {
						let data = res.data;
						let info = data.info
						this.form = {
							"id": data.id || '',
							"title": data.title || '',
							"type": data.type || '',
							"hourNum": info.hourNum || '',
							"cardRules": '',
						}
						//规则
						if (info.cardRules) {
							this.cardRules = info.cardRules.map(v => ({
								...v,
							}))
						}
					}
				});
			},

			query_daiban_detail() {
				this.loading=true
				this.$api("kaoqin/getRuleInfo", {
					// id: this.id
					type: 3
				}, "get").then((res) => {
					this.loading=false
					if (res.code == 200) {
						let data = res.data;
						let info = data.info
						this.form_daiban = {
							"id": data.id || '',
							"title": data.title || '',
							"type": data.type || '',
							hourNum: info.hourNum || '',
							"departIds": info.departIds || '',
							departItems: info.departItems ? JSON.stringify(info.departItems) : ''
						}
	
						//部门
						if (info.departItems) {
							this.$nextTick(() => {
								this.$refs.bumen_cascader.init({
									type: '设置数据',
									value: info.departItems
								});
							})

						}
					}
				});
			},



			delItem(i) {
				this.cardRules.splice(i, 1);
			},
			addItem(i) {
				let info = {
					"position": "",
					"code": ""
				}
				this.cardRules.push(info);
			},



			do_save() {
				if (!this.form.hourNum) {
					alertErr('请填写下井时间设置')
					return
				}

				let need_fill_rules = this.cardRules; //.filter(v => v.is_show)
				let empty_rule = null
				need_fill_rules.forEach(v => {
					if (!empty_rule && !v.position || !v.code) {
						empty_rule = v;
					}
				})

				if (empty_rule) {
					alertErr('请将打卡点位信息补充完整')
					return
				}
				if (!this.form_daiban.hourNum) {
					alertErr('请填写下井带班时间')
					return
				}
				let lists=JSON.parse(this.form_daiban.departItems)
				if (lists.length==0) {
					alertErr('请填写下井带班部门')
					return
				}
				

				let params = {
					...this.form
				}
				params.cardRules = JSON.stringify(need_fill_rules)
				this.$log('要提交的表单', params)

				this.loading=true
				this.$api(
					'kaoqin/saveRule', params,
					'post'
				).then((res) => {
					alert(res)
					this.loading=false
					if (res.code == 200) {
					}
				});
				//

				let params_daiban = {
					...this.form_daiban
				}

				this.$api(
					'kaoqin/saveRule', params_daiban,
					'post'
				).then((res) => {
					// alert(res)
					if (res.code == 200) {

					}
				});

				//下井带班

			},

			confirm_bumen_cascader_value(value) {
				this.$log('勾选部门数据', value)
				let last_id_arr = value.map(arr => arr[arr.length - 1])
				this.form_daiban.departIds = last_id_arr.join(',')
				this.form_daiban.departItems = JSON.stringify(value)
			}

		}
	};
</script>

<style scoped lang="less">
	.bor {
		border: 1px solid red;
	}

	.Downhole_settings {
		background: #fff;
		border-radius: 8px 8px 8px 8px;
		min-height: calc(100vh);
		padding-bottom: 30px;

		.btns {
			padding: 30px 0;
			margin-left: 150px;

			button {
				min-width: 179px;
			}
		}

		.body {
			padding: 35px;

			.form-wrap+.form-wrap {
				padding-top: 30px;
				border-top: 1px solid #ccc;
			}

			.form-wrap {
				padding: 0px 10px;
				// width: 0;
				margin-left: 40px;

				.bitem {
					width: 890px;
					// min-height: 234px;
					padding: 20px 14px 17px;
					margin-bottom: 16px;
					background: #f8f8f8;
					border-radius: 4px;

					.title {
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						padding: 0 10px 10px;
					}

					.inner {
						display: flex;
						flex-wrap: wrap;

						.fitem {
							flex: 1;

							label {}
						}
					}
				}

				.fitem.req {
					&>label {
						&:before {
							display: inline-block;
							content: '*';
							padding-right: 5px;
							text-align: right;
							color: red;
							line-height: 40px;
						}
					}
				}

				.fitem {
					display: flex;
					margin-bottom: 16px;

					label {
						&::after {
							content: ' :';
						}

						display: inline-block;
						min-width: 150px;
						text-align: right;
						padding-right: 10px;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 40px;
					}

					.dianwei-wrap {
						display: flex;
						flex-direction: column;

						.add-wrap {
							padding-top: 10px;
						}

						.dianwei_list {
							display: flex;
							flex-wrap: wrap;
							width: 750px;

							.dwitem+.dwitem {
								margin-top: 18px;
							}

							.dwitem {
								display: flex;

								.el-input {
									margin-right: 25px;
								}

								// .bor();
							}
						}
					}

					.value {
						flex:1;
						max-width: 900px;
						display: flex;
						align-items: center;

						// flex: 1;
						p {
							margin-left: 14px;
							font-weight: 400;
							font-size: 14px;
							color: #333333;
							line-height: 40px;
						}

						// .bor();
					}
				}
			}
		}

		.head {
			padding: 20px 37px;

			h4 {
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}
		}
	}
</style>